.rowProgress {
    font-size: 13px;
    padding: 4px;
}

.current_state {
    background-color: $current-state-color;
}

.complete_state {
    background-color: $complete-state-color;

    &.is-last-state {
        background-color: $complete-last-state-color;
    }
}

.next_state {
    background-color: $next-state-color;
}
