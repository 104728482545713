@import "mixin";

@media (min-width: 768px) {


    .smallTwoRows.default-all-row>*>* {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    @include fieldobjectGrid(smallTwoRows, 2);
    @include fieldobjectGrid(smallThreeRows, 3);
    @include fieldobjectGrid(smallFourRows, 4);
    @include fieldobjectGrid(smallFiveRows, 5);

    @for $i from 2 through 5 {
        @include fieldobjectGrid(grid-#{$i}-columns, $i);
    }

}

