@font-face {
    font-family: 'Montserrat';
    src: url("../../public/fonts/Montserrat-VariableFont_wght.ttf");
}
/* Provide sufficient contrast against white background */
*, html body {
    font-size: 12pt;
    scroll-margin-top: 6rem;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
}

body {
    background-color: $gray !important;
}

.app-body {
    display: flex;
}

a.nav-link {
    cursor: pointer;
}

.dont-show-component {
    display: none;
}


// .inbox-table {
//     margin-left: auto;
//     margin-right: auto;
// }
// @media (min-width: 1300px){
// .inbox-table {
//     max-width: 1240px;
// }}

// @media (min-width: 1400px){
// .inbox-table {
//     max-width: 1340px;
// }}

// @media (min-width: 1600px){
// .inbox-table {
//     max-width: 1540px;
// }}

// @media (min-width: 1800px){
// .inbox-table {
//     max-width: 1740px;
// }}

// @media (min-width: 2000px){
// .inbox-table {
//     max-width: 1940px;
// }}

// @media (min-width: 2200px){
// .inbox-table {
//     width: 2140px;
// }}

// @media (min-width: 2400px){
// .inbox-table {
//     width: 2340px;
// }}

legend {
    font-weight: bold;
    font-size: 1.75rem;
}

a {
    color: $info;
}

.field-description {
    font-weight: bold;
    font-size: 1.4rem;
    font-style: italic;
}

.control-label {
    font-weight: bold;
    font-size: large;
    margin-bottom: 1rem;
}

input[type=radio] {
    margin-right: 0.75rem;
}

#root__title {
    margin-bottom: 1.25rem;
    font-weight: bold;
}

.form-title {
    text-align: center;
    margin-bottom: 2rem;
}


.step-circle {
    background: $primary;
    margin: 0px auto 0.5rem auto;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.step-title {
    color: $primary;
    opacity: 0.5;

    &.active {
        opacity: 1;
        font-weight: 700;
    }
}


.field-radio-group {
    display: flex;
    flex-wrap: wrap;

    .radio {
        margin-right: 1rem;
    }
    
    .radio-inline {
        margin-right: 1rem;
    }
}

input[type=checkbox] {
    margin-right: 1rem;
}

.card.clickable {
    &:hover {
        background: $light;
        cursor: pointer;
    }
}


.sort-button {
    white-space: nowrap;
}


.form-submit-buttons {
    > :not(:first-child) {
        margin-left: 45px;
    }
}


div.is-a-p {
    margin-bottom: 1rem;
}


// div.search-bar {
//     margin-bottom: 24px;
// }


nav > .collapse > ul.navbar-nav:not(:last-child) {
    flex-grow: 1;
}


.nowrap {
    white-space: nowrap;
}


.banner-heading{
    background-color: $primary;
    color: $primary-text;
    box-sizing: content-box;
}


.no-margin {
    margin: 0 !important;
}

.btn-submit{
    background-color: $pd-color-4;
    color: $white;
}

.btn-save{
    background-color: $pd-color-7;
    color: $white;
}