
.message-view {
    margin-top: 3rem;

    .card-header {
        font-size: 1.75rem;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        background-color: $primary;
        color: $white;
    }
}