@use "sass:map";
$jedi-colors: (
    primary: (
        fg: #fff, bg: #070034, border: #1861ac,
        hover-bg: #0069d9, hover-border: #0062cc,
    ),
    secondary: (
        fg: #fff, bg: #6c757d, border: #6c757d,
        hover-bg: #5a6268, hover-border: #545b62,
    ),
);

$size: 45px;
$bigsize: 135px;
$originx: 1px;
$originy: -2px;
$offset: 3px;
$animlength: 7s;

.jedi-form {

    background-color: transparent !important;

    .card {
        background-color: transparent;
        border-radius: 0px;
        border: 0px;
    }

    .card-body {
        padding-left: 0px !important;
    }

    .field .form-group:not(.field-array) {
        display: flex; //field-array
    }

    .field .form-group .form-group {
        display: contents; //field-array
    }

    label.control-label {
        font-size: 14px;
        color: #2c00a6;
        font-weight: bold;
        width: 16.666667% !important;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    .btn {
        box-sizing: border-box;
        margin: 0;
        font-family: inherit;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        cursor: pointer;
        margin-top: 10px;
        margin-right: 5px;

        @each $name, $clmap in $jedi-colors {
            &.btn-#{$name} {
                color: map.get($clmap, "fg");
                background-color: map.get($clmap, "bg");
                border-color: map.get($clmap, "border");
    
                &:hover {
                    color: map.get($clmap, "fg");
                    background-color: map.get($clmap, "hover-bg");
                    border-color: map.get($clmap, "hove-border");
                }
            }
        }
    }

}