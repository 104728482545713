.checkbox-toggle-button-field {
    padding: 0;
    > .checkbox {
        padding: 5px 7px;
        > input {
            position: relative;
            margin-left: 0;
            margin-right: 12px;
        }
        user-select: none;

        &.checkbox-hidden {
            > input {
                width: 0;
                margin-right: 0;
            }
        }
    }
}