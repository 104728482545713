.select-image-field {
    $columns : 6;
    $rows: 8;
    $icon-button-size: 42px;
    .dropdown-menu {
        max-width: none;
        position: relative;
        margin-top: 44px;

        > .list-tabs {
            position: absolute;
            left: -1px;
            top: -42px;
            background-color: $dropdown-bg;
            padding: 5px 6px 0px 6px;
            border: 1px solid $dropdown-border-color;
            border-radius: 5px 5px 0px 0px;
            border-bottom-width: 0;
        }
        > .button-list {
            display: grid;
            grid-template-columns: repeat($columns, $icon-button-size);
            // width: 200px;
            max-height: $icon-button-size * $rows;
            overflow-y: auto;
            overflow-x: hidden;
            margin: 0 6px;

            > button {
                position: relative;
                > .idx {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: .6rem;
                    white-space: nowrap;
                    $shadow: 0px 0px 2px #fff;
                    text-shadow: $shadow, $shadow, $shadow;
                }
            }
        }
    }
}