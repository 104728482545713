.form-clients-tabs {
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 60px;
    margin-top: 1rem;

    >.nav-item {

        &.add-button {
            background-color: white;
            // position: fixed;
            // right: 47px;
        }

        >.nav-link {
            white-space: nowrap;
            color: black !important;

            &:hover {
                background-color: rgba($color: #EABF08, $alpha: 0.4);
            }

            &.active {
                background-color: #EABF08;
                border-color: #EABF08;
                color: white !important;
            }
        }
    }
}