@import "mixin";


@include fieldobject(flex-h) {
    display: flex;
    flex-direction: row;
    > :not(:first-child) {
        margin-right: 12px;
    }
}
