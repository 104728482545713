.welcome-box {
    background-color: $white;
    margin-top: 5rem;
    padding: 18px 25px 18px 25px;
    border-top: 22px solid $pd-color-4;
    border-radius: 11px;
    border-bottom: 22px solid $pd-color-4;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    .title {
        font-size: 29px;
        font-weight: bold;
        color: $pd-color-4;
    }
    .subtitle {
        padding-top: 5px;
        font-weight: bold;
        color: $pd-color-5;
    }
    .text {
        padding-top: 5px;
        padding-bottom: 12px;
    }
}