.file-input > label.file-input-label > input[type=file] {
    display: none;
}

.file-input > label.file-input-label {
    margin: 0;
}

.file-input > .input-group-text {
    min-width: 10em !important;
    max-width: 15em !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    background-color: #fff;
}

.file-input > :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.file-input > :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}