@import "mixin";


@include fieldobject(personalComplianceUploads-rdd) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        text-align: center;
        > a {
            width: 100%;
            max-width: 280px;
        }
        margin: 0;
    }

    .banco  {grid-column: 1;}
    .puesto {grid-column: 2;}
    .safi   {grid-column: 3;}
    .reporte { grid-row: 1; }
}

@include fieldobject(personalComplianceUploads-upload) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        > .file-input {
            flex-wrap: nowrap;
            > .input-group-text {
                width: auto; flex-shrink: 100; max-width: 15em;
            }
            .btn {
                white-space: nowrap;
            }
        }
    }
}
