

.user {
    display: grid;
    grid-template-columns: auto 100px;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    
    .name {
        display: block;
        grid-area: 1/1/1/1;
    }
    .role {
        display: block;
        grid-area: 2/1/2/1;
        font-size: small;
    }
    .branch {
        grid-area: 1/2/1/2;
        font-size: small;
        text-align: right;
        justify-self: center;
    }
}