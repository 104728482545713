svg.workflow {
    background-color: white;
    border: black 1px solid;


    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    .step {
        text {
            fill: black;
        }
        rect.shape {
            rx: 2px;
            fill:white;
            stroke:black; 
        }
        rect.squaredCorners {
            fill:white;
            stroke:black; 
        }
        circle.shape {
            fill:white;
            stroke:black; 
        }
        polygon.shape {
            fill:white;
            stroke:black; 
        }
        path.shape {
            fill:white;
            stroke:black; 
        }
    }

    .selection {
        stroke: blue;
        fill: transparent;
        stroke-dasharray: 3;
        stroke-width: 1;
        rx: 2px;
        stroke-dashoffset: 0%;
        transition: k;
        vector-effect: non-scaling-stroke;
    }

    .draggable {
        cursor: move;
    }

    path {
        stroke: black;
    }
}

svg text {
    font-family: 'Font Awesome 5 Free';
 }


svg.is-panning {
    cursor: grabbing;
}