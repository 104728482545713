.sidebar {
    $bg-color-active: $gray;
    $bg-color-inactive: $white;
    $bg-color-inactive-2: $pd-color-2;
    $icon-color: $pd-color-3;
    $icon-color-inactive: $pd-color-7;

    position: relative;
    left: 0;
    top: 75px;
    background-color: $bg-color-inactive;
    bottom: 0;
    min-width: $sidebar-width;
    min-height: 100vh;
    z-index: 1000;
    
    transition: 200ms ease-in-out;
    display: flex;
    flex-direction: row;

    >.nav.nav-tabs {
        position: fixed;
        flex: 0 0 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        width: $sidebar-width;
        height: calc(100vh - $navbar-height);
        background-color: $bg-color-inactive;
        overflow-x: hidden;
        overflow-y: scroll;
        transition: 200ms ease-in-out;
        li a {
            display: flex;
        }
        >.nav-item {
            >.nav-link {
                border-radius: 0;
                border-width: 0 0 0 5px;
                border-color: $bg-color-inactive;
                padding-left: 0px;
                width: 100%;
                color: $icon-color-inactive;

                >* {
                    opacity: .9;
                }

                &.active {
                    background-color: $bg-color-active;
                    border-color: $icon-color;
                    color: $icon-color;

                    >* {
                        opacity: 1;
                    }
                }
                >span:first-child{
                    min-width: 45px;
                }
                >i:first-child{
                    min-width: 45px;
                }
            }

            .fa,
            .icon,
            .dimo-icon {
                font-size: 1.9rem;
                text-align: center;
                max-width: 45px;
            }
        }
    }
    
    >.tab-content {
        $item-gap: 42px;
        flex: 0 0 $sidebar-button-width;
        background-color: $bg-color-active;

        &.empty {
            flex: 0 0 0;
        }

        >.tab-pane {
            padding-top: $main-container-gap;
        }

        >.tab-pane.home {
            display: flex;
            flex-direction: column;
            align-items: center;

            > :not(:first-child) {
                margin-top: $item-gap;
            }

            .sidebar-status-icon {
                width: 75%;
            }
        }
    }
    &.is-collapsed {
        >.nav.nav-tabs {
            width: $sidebar-logo-width;
            transition: 200ms ease-in-out;
        }
        .nav-text {
            display:none;
        }
        min-width: $sidebar-logo-width;
        transition: 200ms ease-in-out;
    } 
}

.sidebar-hamburger {
    top: 16px;
    left: -4px;
    z-index: 1060;
    position: fixed;
    padding: 7px;

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    :hover {
        background-color: $light-gray;
    }
}

.sidebar-status-icon {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    :nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;
        align-self: stretch;
        height: 1em;
        text-align: center;
        font-size: 4rem;
    }

    :nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
        background-color: $gray;
        height: 1.5em;
        text-align: center;
    }

    :nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
        background-color: $gray;
        height: 1.5em;
        text-align: center;
    }

    :nth-child(4) {
        grid-area: 3 / 1 / 4 / 3;
    }
}

@media print { 
    .sidebar { 
      display: none!important;
     } 
}