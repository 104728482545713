.form-control.signature {
    position: relative;
    display: block;
    box-sizing: content-box;
    > .button-bar {
        position: absolute;
        > .btn {
            font-size: .5rem;
            > i {
                font-size: .7rem;
            }
        }
        > label { margin-bottom: 0; }
        > .gap {
            display: inline-block;
            width: 12px;
        }

        &.tr {
            top: 0;
            right: 0;
        }
    }
    > .signature-pad {
        padding: 0;
        &.writable {
            cursor: crosshair;
        }
    }
    > .no-signature {
        user-select: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    input[type="file"] {
        display: none;
    }

    // .custom-file-upload {
    //     border: 1px solid #ccc;
    //     display: inline-block;
    //     padding: 6px 12px;
    //     cursor: pointer;
    // }

}