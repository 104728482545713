@media only screen and (max-width: 1000px) {
    .uweentity-form.form-component>.form-component {
        margin-left: 5px;
        margin-right: 5px;
    }

    form.rjsf .form-group .control-label {
        margin-bottom: 0.2rem;
    }

    .form-container {
        display: flex;

        >.sidepanel {
            display: none;

        }
    }

    .dashboard {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding-right: 0;
        width:100%;
        overflow-x:auto;
    }

    nav.navbar {
        padding-left: 0px;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    .navbar-collapse {
        background-color: white;
        opacity: 98%;
        border: 2px solid lightgray;
    }

    .nav-notification-mobile {
        display: block;
    }

    .nav-logo-icon {
        margin-left: 50px;
    }

    .sidebar {
        position: fixed;
        max-width: 250px;
        z-index: 1050;
    }

    .sidebar-hamburger {
        top: 15px;
        left: 0px;
        z-index: 1060;
        position: fixed;
        padding: 7px;
        :hover {
            background-color: transparent;
        }
    }

    .hide-when-mobile {
        display: none !important;
    }

    .show-when-mobile {
        display: block;
    }

    .is-collapsed {
        display: none !important;
    }

    .is-not-collapsed {
        display: block;
    }

    .main-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pagination {
        padding-left: 0px;
        padding-right: 0px;
    }

    .sidebar>.nav.nav-tabs>.nav-item>.nav-link {
        display: flex !important;
        align-items: center !important;
        width: 100% !important;
    }

    .sidebar>.nav.nav-tabs>.nav-item>.nav-link>* {
        padding-right: 10px !important;
        :first-child{
            width: 20px;
        }
    }

    .navbar-expand-sm .navbar-collapse {
        display: none !important;
    }

    .nav-notification-mobile {
        position: absolute;
        right: 15px;
    }

    *,
    html body {
        font-size: 11pt !important;
    }

    .navbar-toggler-icon {
        font-size: 12pt !important;
    }

    .nav-text {
        position: initial !important;
        padding-left: 0px !important;
    }
    
}

tbody tr td:has(a.btn) {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.worktray>.card>.table>*>tr>td>.btn,
.worktray>.card>.table>*>tr>th>.btn {
    flex-grow: 1;
    margin-inline: 5px;
    background-color: rgb(16, 17, 43);
    max-width: 90px;
}
