.pdf-viewer {
    position: relative;
    height: 75vh;
    border: 1px solid $black;

    > .document-container {
        position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    > .controls {
        position: absolute;
        background-color: $white;
        padding: 5px;
        top: 0;
        left: 12px;
        display: flex;
        > .control {
            &:not(:last-child) {
                margin-right: 12px;
            }

            > label {
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 0;
            }
        }
    }
}