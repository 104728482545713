.card {
    border-radius: $border-radius;

    &.square {
        border-radius: 0;
    }

    &.square-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &.square-bottom {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    > .card-header{
        border-radius: $border-radius $border-radius 0 0;

        .title {
            font-size: x-large;
            font-weight: bold;

            &.has-buttons {
                display: inline-block;
                vertical-align: bottom;
                padding-right: 2rem;
            }
        }

        .pagination {
            margin-bottom: 0;
        }
    }

    > .card-footer {
        border-radius: 0 0 $border-radius $border-radius;
        .pagination {
            margin-bottom: 0;
        }
    }

    > table {
        > thead th { font-weight: normal; }
    }
    
    &.primary {
        background-color: $gray;
        color: $black;
        > table {
            background-color: $primary-card-bg2;
            > thead * { color: $primary; }
            > tbody {
                > tr {
                    margin-top: 5px;
                    background-color: $primary-card-bg2;
                }
            }
        }
        > .card-header {
            color: $white;
            background-color: $pd-color-4;
        }
    }   

    > .flex{
        display: flex;
        > .chart-border-right{
            flex: 1;
            border-right: 1px solid $primary;
        }
        > .chart-summary-completed{
            flex: 1;
            max-width: 15%; 
            display: flex;
            align-items: center;
    
            >.card-body {
                text-align: center;
                margin: auto !important;
                > .chart-total{
                    font-size: medium;
                    font-weight: bold;
    
                }
                > .chart-total.total {
                    font-size: large;
                    font-weight: bold;
                    color:$pd-color-4;
                }
            }
        }
    
    }
}

.card.inbox-card {
    margin-bottom: $main-container-gap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}


.no-bottom-gap {
    margin-bottom: 0 !important;
}