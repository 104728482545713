$height: 24px;
$gap: 4px;
$width: 2*$height;

.switch {
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;
    vertical-align: middle;
    margin-left: $gap;
    margin-right: $gap;

    > input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &.round > .slider {
        border-radius: $height;
        &:before {
            border-radius: 50%;
        }
    }

    $robg: scale-color(#2196F3, $saturation: -80%, $lightness: 10%);
    --slider-bg: #ccc;
    --slider-checked-bg: #2196F3;
    --slider-checked-readonly-bg: #{$robg};
    --slider-button: white;

    > .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--slider-bg);
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: $height - 2 * $gap;
            width: $height - 2 * $gap;
            left: $gap;
            bottom: $gap;
            background-color: var(--slider-button);
            -webkit-transition: .4s;
            transition: .4s;
        }
    }

    &.readonly > .slider {
        background-color: var(--slider-checked-readonly-bg) !important;
    }

    > input:checked + .slider {
        background-color: var(--slider-checked-bg);
    }

    > input:focus + .slider {
        box-shadow: 0 0 1px var(--slider-checked-bg);
    }

    > input:checked + .slider:before {
        $tx: $width - $height;
        -webkit-transform: translateX($tx);
        -ms-transform: translateX($tx);
        transform: translateX($tx);
    }
}


@each $name, $color in $theme-colors {
    .switch-#{$name} {
        $bg: scale-color($color, $saturation: -90%, $lightness: +20%);
        $robg: scale-color($color, $saturation: -80%, $lightness: 10%);
        --slider-checked-bg: #{$color};
        --slider-bg: #{$bg};
        --slider-checked-readonly-bg: #{$robg};    
    }
}