.collapsable-tree-select {
    > .dropdown-toggle {
        display: flex;
        flex-direction: row;
        max-width: 20em;

        > span {
            display: inline-block;
            width: 97%;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1em;
        }
    }
    > .dropdown-menu {
        max-height: 350px;
        overflow-y: auto;
        > .dropdown-item {
            padding: 0 6px;
            > .depth-prefix {
                font-family: monospace;
                padding-right: 3px;
            }
            > .btn {
                padding: 0 6px 0 0;
            }
        }
    }
}
