li.dropdown ul {
    display: none;
    /*To hide the items while loading the page */
}


.divComponents {

    ul,
    li {
        list-style-type: none;
        cursor: pointer;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }

    li {
        ul:last-child {
            li {
                border-bottom: 0px;
            }
        }
    }

    .shadowBack:hover {
        background-color: #b8b8b84d;
    }

    .dropdown,
    .cssState {
        padding: 6px;
        border-bottom: 1px solid #e3e3e3;
    }

    ul {
        margin: 0;
        padding-inline-start: 25px;

        .dropdown,
        .cssState {
            padding: 3px;
        }

    }


    .pr-10 {
        padding-right: 10px;
    }

    i:not(button) {

        span {
            font-size: 12px;
            font-weight: normal;
            padding: 10px;
            display: none;
        }
    }



    .f-draggable {
        font-weight: bold;
    }

    .droptarget {
        &.up {
            border-top: 3px solid green !important;
        }

        &.down {
            border-bottom: 3px solid green !important;
        }
    }

    .grip-icon {
        display: inline-block;
        width: 1.5em;
        text-align: center;
    }

    .expand-icon {
        display: inline-block;
        width: 1em;
    }

    .dropZone {
        width: 100% !important;
    }

    .lnkAddOption {
        padding: 7px;
        font-size: 13px;
        font-weight: bold;
        float: right;
        padding-right: 20px;
        cursor: pointer;
    }

}

// .container {
//     width: 70%;
//     float: left;
// }

// /* Main style part for the menu and items look and feel */
// .container .cssState {
//     float: left;
//     display: block;
//     border-top-style: solid;
//     border-top-width: 1px;
//     border-top-color: black;
//     border-bottom-width: 1px;
//     border-bottom-color: grey;
//     border-bottom-style: solid;
//     width: 200px;
//     border-right-style: solid;
//     border-right-color: white;
//     border-right-width: 10px;
//     padding: 2px;
//     cursor: pointer;
//     font-weight: bold;
// }

.divComponents {
    background: white;
}

.sidebar-panel:first-child>.titleComponents {
    border-radius: $border-radius $border-radius 0 0;
}

.titleComponents {
    position: relative;
    background-color: #080a2c;
    color: white;
    // border-radius: $border-radius $border-radius 0 0;
    box-sizing: content-box;
    margin-bottom: 0px;
    padding-left: 1.25rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: 1.3rem !important;

    >.dropdown {
        margin-left: -1rem;

        >.btn.dropdown-toggle {
            background: transparent;
            color: white;
            border-width: 0;
            line-height: 1em;
        }
    }

    .buttons {
        position: absolute;
        top: 0;
        right: 12px;

        >.btn {
            background-color: transparent;
            border-width: 0;
            color: #ffffff;
        }
    }

}

._formEditor {
    .title {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 0.9rem;
    }

    .form-check {
        padding-top: 5px;
        padding-bottom: 5px;
    }

}

._workflowEditor {
    .nav-link {
        padding: 4px;
        border-radius: 0px;
        border: none;
        color: black;
    }

    .nav-tabs {
        padding-inline-start: 0;
    }

    .nav.nav-tabs .nav-link.active {
        border-color: #080a2c;
        color: white;
        background-color: #080a2c;
    }

    li.nav-item {
        margin: 0px auto !important;
    }

    .nav-link{
        color: #080a2c !important;
    }
    .nav.nav-tabs .nav-link.active{
        color: white !important;
    }
}

.is-sticky {
    position: fixed;
    top: 81px;
    z-index: 999;
    right: 0;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-left: 30px;
    padding-right: 30px;
}