@import "mixin";


@include fieldobject(personalNonCCD-dct) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        text-align: center;
        > a {
            width: 100%;
            max-width: 280px;
        }
        margin: 0;
    }

    .banco  {grid-column: 1;}
    .puesto {grid-column: 2;}
    .safi   {grid-column: 3;}
    .title                    { grid-row: 1; align-self: flex-end; justify-self: center; }
    .tarjetaFirma             { grid-row: 2; }
    .informeInversionista     { grid-row: 3; }
    .contratoMarco            { grid-row: 4; }
    .contratoCuenta           { grid-row: 5; }
    .cartaAutorizacionCuenta  { grid-row: 5; }
    .formularioCliente        { grid-row: 6; }
    .formularioW8Ben          { grid-row: 7; }
    .formularioW9             { grid-row: 7; }
    .formularioMoneyline      { grid-row: 8; }
    .formularioSujetoObligado { grid-area:  9 / 1 /  9  / 1; }
    .solicitudPrestamoDL      { grid-area: 10 / 1 / 10  / 1; }
}

@include fieldobject(personalNonCCD-upload) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        > .file-input {
            flex-wrap: nowrap;
            > .input-group-text {
                width: auto; flex-shrink: 100; max-width: 15em;
            }
            .btn {
                white-space: nowrap;
            }
        }
    }
}
