@import "mixin";


@include fieldobject(soporteDebidaDiligencia) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-input > .input-group-text {
        width: 10em;
    }
    .aditionalFiles  {grid-column-start: 1; grid-column-end: 3}
}
