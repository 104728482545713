.textModalConfirm {
    text-align: center;
    font-weight: bold;
    letter-spacing: -2px;
    color: #080A2C;
    opacity: 1;
    font-size: 25px;
    line-height: 25px;
}

.textBottomModalConfirm {
    letter-spacing: 0px;
    color: #004070;
    opacity: 1;
    padding-top: 15px;
    text-align: center;
}