$formtab-color: $primary;
$formtab-hover-color: darken($formtab-color, 20%);
$formtab-hover-bg: transparentize($formtab-color, .95);


.uweentity-form-nav-panel {
    color: $formtab-color;
    overflow: hidden;
    max-height: calc(100vh - 200px);
    height: 100%;

    .scroller {
        overflow: hidden auto;
        max-height: calc(100vh - 200px);
    }

    &.expanded {
        max-width: 320px;
        .scroller {
            padding: 1rem 1rem;
            max-width: 320px;
        }
        border-radius: 0.5rem;
        text-align: center;
    }

    h5 {
        margin-bottom: .5rem;
        cursor: default;
    }

    ul.nav {
        border-top: 1px $formtab-color solid;
        border-bottom: 1px $formtab-color solid;
    }

    a {
        color: $primary-text-color3;
        cursor: pointer;
    }

    .nav-item{
        border-radius: 0.5rem;

        > * {
            border-left-width: 7px;
            border-left-style: solid;
            border-radius: 0;
            border-color: transparent;
        }

        > :hover {
            color: $formtab-hover-color;
            background: $formtab-hover-bg;
        }

        > .active {
            border-left-color: $formtab-color;
            background: $primary-card-bg2;
            color: $primary-text-color3;
            // box-shadow: rgba(153, 101, 101, 0.35) 0px 5px 15px;
        }
    }

    .separator {
        height: 1px;
        background-color: $formtab-color;
        margin: 0 10%;
    }
  
    .section-title {
        color: $formtab-color;
    }
}