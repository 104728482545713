.organizational-unit-tree {
    svg {
        .rd3t-node, .rd3t-leaf-node {
            transition: all 0.5s ease-in-out;
        }
    }
}

.organizational-unit-tree-node {
    padding-top: 500px;

    > foreignObject {
        > div {
            border: 1px solid black;
            background-color: $white;
            border-radius: 5px;
            > .type {
                padding: 0 5px;
                font-size: .8rem;
                background-color: $pd-color-5;
                color: $white;
            }
            > .body {
                padding: 5px 5px;
                min-height: 3em;
                display: flex;
                align-items: center;
                justify-content: center;

                > h3 {
                    text-align: center;
                    margin: 0;
                    font-size: 14px;
                }
            }
            > .buttons {
                display: flex;
                justify-content: stretch;

                > button {
                    flex-grow: 1;
                    font-size: 12px;
                }
                > :not(:first-child) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
                > :not(:last-child) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}