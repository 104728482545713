.modal {
    > .modal-dialog {
        &.modal-primary {
            > .modal-content {
                >.modal-header {
                    background-color: $pd-color-4;
                    color: $white;
                    > .close {
                        opacity: .8;
                        text-shadow: none;
                        &:hover {
                            opacity: 1;
                        }

                        > span {
                            color: $white;
                            font-size: 2rem;
                            opacity: 1;
                            text-shadow: none;
                        }
                    }
                }
            }
        }

        > .modal-content {
            border-radius: $border-radius;
            >.modal-header {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
        }

        &.maximized {
            max-width: 96vw;
            max-height: 96vh;
            height: 94%;
            margin: 2%;
            display: flex;

            > .modal-content {
                justify-content: stretch;
                max-height: 96vh;
            }
        }
    }
}