.form-control.interactive-drawing {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border: 1px solid #f4f4f4;
    > .button-bar {
        position: absolute;
        > .btn {
            font-size: .5rem;
            > i {
                font-size: .7rem;
            }
        }
        > label { margin-bottom: 0; }
        > .gap {
            display: inline-block;
            width: 12px;
        }

        &.tr {
            top: 0;
            right: 0;
        }
    }
    > .signature-pad {
        padding: 0;
        &.writable {
            cursor: crosshair;
        }
    }
    > .no-signature {
        user-select: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .sign {
        width: 100%;
        height: 100%;
      }

}

