$size: 45px;
$bigsize: 135px;
$originx: 1px;
$originy: -2px;
$offset: 3px;
$animlength: 7s;


.uweentity-loader {
    // z-index: 100000;
    position: relative;
    // top: 25%;
    // left: 25%;
    width: $size;
    height: $size;
    margin: 90px auto;

    .spinner {
        animation: spin $animlength ease-in-out infinite;
        width: $size;
        height: $size;
    }

    .bubble {
        position: absolute;
        border-radius: 100%;
    }

    .bubble-1 {
        bottom: 0;
        width: $size;
        height: $size;
        background-color: #EC1C2C;
        animation: bounce-1 $animlength ease-in-out infinite;
    }
    
    .bubble-2 {
        right: $originx + $offset;
        bottom: $originy + $offset;
        width: $size * 5 / 7;
        height: $size * 5 / 7;
        animation-delay: -2s;
        background-color: #6A6B6C;
        animation: bounce-2 $animlength ease-in-out infinite;
    }
    
    .bubble-3 {
        right: $originx + 2 * $offset;
        bottom: $originy + 2 * $offset;
        width: $size * 2 / 5;
        height: $size * 2 / 5;
        animation-delay: -3.0s;
        background-color: #EABF08;
        animation: bounce-3 $animlength ease-in-out infinite;
    }

    &.big {
        transform: scale(2);
    }

}


@keyframes spin {
    0%   { transform: rotateY(0     ); opacity: 0;}
    20%  { transform: rotateY(0     ); opacity: 1;}
    40%  { transform: rotateY(180deg); opacity: 1;}
    45%  { transform: rotateY(180deg); opacity: 1;}
    80%  { transform: rotateY(360deg); opacity: 1;}
    100% { transform: rotateY(360deg); opacity: 0;}
}

@keyframes bounce-1 {
    0%   { transform: scale(0.0); }
    40%  { transform: scale(1.0); }
    60%  { transform: scale(1.0); }
    100% { transform: scale(0.2); }
}

@keyframes bounce-2 {
    0%   { transform: scale(0.0); }
    20%  { transform: scale(0.0); }
    40%  { transform: scale(1.0); }
    60%  { transform: scale(1.0); }
    80%  { transform: scale(0.0); }
    100% { transform: scale(0.0); }
}

@keyframes bounce-3 {
    0%   { transform: scale(0.0); }
    30%  { transform: scale(0.0); }
    40%  { transform: scale(1.0); }
    60%  { transform: scale(1.0); }
    70%  { transform: scale(0.0); }
    100% { transform: scale(0.0); }
}