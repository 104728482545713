div.item-details .title {
    font-size: 13px;
    font-weight: bold;
    margin-right: 20px;
    display: flex;
}

div.item-details label.title {
    font-size: 13px;
    font-weight: bold;
    display: flex;
}

div.item-details label {
    font-weight: bold;
    margin-bottom: 0px;
}

div.item-details ul {
    list-style: none;
    margin: 0px;
    padding-left: 0px;
}

div.item-details li {
    padding-top: 3px;
    padding-bottom: 3px;
    min-height: 45px;
}

div.item-details ul.horizontal {
    margin: 0px;
    padding-left: 0px;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

div.item-details .sectionTitle {
    width: 94%;
    margin-left: 3%;
    text-align: center;
    position: relative;
    color: #080A2C !important;
}

div.item-details .sectionTitle h1 {
    position: relative;
    padding: 10px;
    background: #fff;
    display: inline-block;
    z-index: 1;
    font-size: 20px !important;
    color: #080A2C !important;
}

div.item-details .sectionTitle::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background: #000;
    z-index: 0;
}